import React, { useState, useEffect, useCallback } from "react";
import { window } from "browser-monads";
import { Link } from "gatsby";
import { navigate } from "@reach/router";
import logo from "../images/pifoam_logo.svg";
import "../styles/menu.css";
import Hamburger from "./Hamburger";

function Menu() {
  const [open, setOpen] = useState(false);
  const style = open ? { minHeight: "calc(100vh - 90px" } : {};

  const onMenuClick = useCallback(() => {
    setOpen(false);
  }, []);

  const resizeHandler = () => {
    if (window.innerWidth >= 1050) setOpen(false);
  };

  const clickOutHandler = (e) => {
    if (
      !e.target.classList.contains("menu__nav_res") &&
      !e.target.classList.contains("hamburger__line") &&
      !e.target.classList.contains("hamburger")
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [resizeHandler]);

  useEffect(() => {
    document.addEventListener("click", clickOutHandler);
  }, []);

  return (
    <header>
      <nav className="menu__nav">
        <Link to="/">
          <img className="menu__logo" src={logo} alt="Logo" />
        </Link>

        <Hamburger open={open} setOpen={setOpen} />

        <div className="menu__nav_res" style={style}>
          <ul>
            <li onClick={onMenuClick}>
              <Link to="/mission">Our Mission</Link>
            </li>
            <li onClick={onMenuClick}>
              <a onClick={() => navigate("/#benefits")}>Benefits</a>
            </li>
            <li onClick={onMenuClick}>
              <Link to="/environment">Environment</Link>
            </li>
            <li onClick={onMenuClick}>
              <Link to="/references">References</Link>
            </li>
            <li onClick={onMenuClick}>
              <Link to="/incidents">Incidents</Link>
            </li>
            <li onClick={onMenuClick}>
              <Link to="/articles">Articles</Link>
            </li>
            <li onClick={onMenuClick}>
              <Link to="/team">About Us</Link>
            </li>
            <li onClick={onMenuClick}>
              <Link to="/faq">FAQ</Link>
            </li>
            <li onClick={onMenuClick}>
              <a href="#footer">Contact</a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Menu;
