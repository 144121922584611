import { Link } from "gatsby";
import React from "react";
import sfprd from "../images/sfprd.svg";
import "../styles/footer.css";

function Footer() {
  return (
    <footer id="footer">
      <div className="footer">
        <div className="footer__company footer__elem">
          <img src={sfprd} alt="sfprd icon" />
          <p>
            Swiss Fire Protection Research and Development AG was established by
            determined industrial fire-safety experts with a singular focus on
            establishing higher safety standards in the energy industry,
            especially when it comes to highly exposed large-diameter
            atmospheric storage tanks.
          </p>
          <Link to="/team">
            <button>Learn More</button>
          </Link>
        </div>
        <div className="footer__faq footer__elem">
          <p>FAQ</p>
          <p>
            Do you have any questions? If something is not clear, please feel
            free to contact us and we will answer you directly. Afterwards, we
            may post your question to this section for the benefit of future
            site visitors.
          </p>
          <Link to="/faq">
            <button>Learn More</button>
          </Link>
        </div>
        <div className="footer__contact footer__elem">
          <p>CONTACT</p>
          <p>
            Make the right decision. Use the Pi Foam System and say goodbye to
            storage-tank disasters once and for all. If you are interested in
            using the Pi Foam System at your storage terminal or would like to
            learn more, we would love to hear from you.
          </p>
          <div className="contact__main">
            <p>Swiss Fire Protection Research & Development AG</p>
            <p>Kernserstrasse 17 CH-6060 Sarnen, Switzerland</p>
            <p>+41 41 6624574</p>
            <p>
              <a href="mailto:info@sfprd.com"> info ‘at’ sfprd ‘dot’ com</a>
            </p>
            <a href="https://sfprd.com" target="blank">
              sfprd.com
            </a>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p>
          COPYRIGHT &copy; 2018-{new Date().getFullYear()} Pressurized Instant
          Foam System
        </p>
        <p>SVG icons are provided by fontawesome.com</p>
      </div>
    </footer>
  );
}

export default Footer;
