import React, { useState } from "react";

function Hamburger({ open, setOpen, style = {} }) {
  return (
    <div
      style={style}
      className="hamburger"
      onClick={() => setOpen((prev) => !prev)}
    >
      <div
        className={`hamburger__line hamburger__line__1 ${open ? "active" : ""}`}
      />
      <div
        className={`hamburger__line hamburger__line__2 ${open ? "active" : ""}`}
      />
      <div
        className={`hamburger__line hamburger__line__3 ${open ? "active" : ""}`}
      />
    </div>
  );
}

export default Hamburger;
