import React from "react";
import { Helmet } from "react-helmet";

function SEO({
  title = "Pi Foam System",
  description = "Investment opportunity in transforming the hydrocarbon industry - eliminate environmental fire-catastrophes in Pi (3.14) minutes with AI. Also, an opportunity to finally eliminate wind turbine fires and wildfires.",
}) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content="https://pifoam.ch/" />
      <meta property="og:site_name" content="Pi Foam System" />
      <meta property="og:image" content="https://pifoam.ch/seo/twitter.jpg" />
      <meta property="og:description" content={description} />
      <meta name="twitter:card" content="summary_large_image"></meta>
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content="https://pifoam.ch/seo/twitter.jpg" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:site" content="@fire_swiss" />
      <link rel="icon" href="/seo/icon192.png" sizes="192x192" />
      <link rel="icon" href="/seo/icon32.png" sizes="32x32" />
      <link rel="apple-touch-icon-precomposed" href="/seo/apple-touch.png" />
      <link rel="shortlink" href="https://pifoam.ch/" />
    </Helmet>
  );
}

export default SEO;
